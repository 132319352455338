<template>
  <div>
    <div v-if="!sentSuccess">
      <h2>
        {{ $t('masteresPosgrados.llamadas.title2') }}
      </h2>
      <h4>
        {{ $t('masteresPosgrados.llamadas.title3') }}
      </h4>
      <br>
      <form
        id="fllamada"
        method="POST"
        action="#"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-input
              id="nombre"
              v-model="form.nombre"
              class="mt-1"
              name="nombre"
              type="text"
              :placeholder="$t('masteresPosgrados.openDay.llamada.fnombre')"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-input
              id="apellido1"
              v-model="form.apellido1"
              class="mt-1"
              name="apellido1"
              type="text"
              :placeholder="$t('masteresPosgrados.openDay.llamada.fapellido1')"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-input
              id="apellido2"
              v-model="form.apellido2"
              class="mt-1"
              name="apellido2"
              type="text"
              :placeholder="$t('masteresPosgrados.openDay.llamada.fapellido2')"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              class="mt-1"
              name="email"
              type="email"
              :placeholder="$t('masteresPosgrados.openDay.llamada.femail')"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-select
              id="pais"
              v-model="form.pais"
              class="mt-1"
              name="pais"
              :options="fpais"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-row>
              <b-col cols="3">
                <b-form-select
                  id="code"
                  v-model="form.code"
                  class="mt-1"
                  name="code"
                  :options="code"
                  required
                />
              </b-col>
              <b-col cols="9">
                <b-form-input
                  id="sms"
                  v-model="form.sms"
                  class="mt-1"
                  name="sms"
                  type="number"
                  :placeholder="$t('masteresPosgrados.openDay.llamada.fsms')"
                  required
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <b-form-select
              id="campus"
              v-model="form.campus"
              class="mt-1"
              name="campus"
              :options="fcampus"
              required
            />
          </b-col>
          <b-col sm="12">
            <b-form-select
              id="master"
              v-model="form.master"
              class="mt-1"
              name="master"
              :options="fmaster"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="pt-1 text-left"
          >
            <b-form-checkbox
              id="aceptar"
              v-model="form.aceptar"
              name="aceptar"
              class="mt-1"
              value="1"
              unchecked-value="0"
              @change="aceptPolitica"
            >
              <span>{{ $t('masteresPosgrados.openDay.llamada.facept1') }}</span>
              <a
                :href="url"
                target="_blank"
              >{{ $t('masteresPosgrados.openDay.llamada.facept2') }}</a>
            </b-form-checkbox>
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="pt-1 text-right"
          >
            <b-button
              class="buttonContacto"
              type="submit"
              :disabled="enviar == 0"
            >
              {{ $t('masteresPosgrados.openDay.llamada.btnEnviar') }}
              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="ml-2"
                size="lg"
              />
            </b-button>
          </b-col>
        </b-row>
      </form>
    </div>
    <div
      v-if="sentSuccess"
      class="send-success"
    >
      <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
      <h3>
        {{ $t('common_contact_form.will_contact') }}
      </h3>
      <div
        class="contact-info text-center mt-4"
        style="color:#fff"
      >
        <h4>Puedes contactarme por</h4>
        <div class="contact-nfo">
          <font-awesome-icon
            icon="phone"
            class="phone-icon"
          />
          <a
            href="tel:+34928354668"
            style="color:#fff"
          >+34 928 354 668</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
div.send-success {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
}
</style>
<script>
export default {
    data() {
        return {
            form: {
                nombre: null,
                email: null,
                apellido1: null,
                apellido2: null,
                pais: null,
                code: '+34',
                sms: null,
                campus: null,
                master: null,
                aceptar: null,
                web: null
            },
            sentSuccess: false,
            sentFailed: false,
            enviar: 0,
            code: [
                { value: '+34', text: '+34 ES' },
                { value: '+33', text: '+33 FR' },
                { value: '+91', text: '+91 IN' },
                { value: '+687', text: '+687 NC' },
                { value: '+689', text: '+689 PF' },
                { value: '+262', text: '+262 YT' },
                { value: '+508', text: '+508 PM' },
                { value: '+681', text: '+681 WF' },
                { value: '+352', text: '+352 LU' },
                { value: '+63', text: '+63 PH' },
                { value: '+27', text: '+27 ZA' },
                { value: '+64', text: '+64 NZ' },
                { value: '+420', text: '+420 CZ' },
                { value: '+31', text: '+31 NL' },
                { value: '+1', text: '+1 PR' },
                { value: '+41', text: '+41 CH' },
                { value: '+44', text: '+44 GB' },
                { value: '+230', text: '+230 MU' },
                { value: '+590', text: '+590 BL' },
                { value: '+594', text: '+594 GF' },
                { value: '+596', text: '+596 MQ' },
                { value: '+269', text: '+269 KM' },
                { value: '+971', text: '+971 AE' },
                { value: '+387', text: '+387 BA' },
                { value: '+880', text: '+880 BD' },
                { value: '+852', text: '+852 HK' },
                { value: '+32', text: '+32 BE' },
                { value: '+39', text: '+39 IT' },
                { value: '+213', text: '+213 DZ' },
                { value: '+49', text: '+49 DE' },
                { value: '+212', text: '+212 MA' },
                { value: '+86', text: '+86 CN' },
                { value: '+81', text: '+81 JP' },
                { value: '+216', text: '+216 TN' },
                { value: '+972', text: '+972 IL' },
                { value: '+30', text: '+30 GR' },
                { value: '+370', text: '+370 LT' },
                { value: '+60', text: '+60 MY' },
                { value: '+92', text: '+92 PK' },
                { value: '+7', text: '+7 RU' },
                { value: '+356', text: '+356 MT' },
                { value: '+20', text: '+20 EG' },
                { value: '+94', text: '+94 LK' },
                { value: '+886', text: '+886 TW' },
                { value: '+234', text: '+234 NG' },
                { value: '+62', text: '+62 ID' },
                { value: '+45', text: '+45 DK' },
                { value: '+98', text: '+98 IR' },
                { value: '+977', text: '+977 NP' },
                { value: '+90', text: '+90 TR' },
                { value: '+376', text: '+376 AD' },
                { value: '+507', text: '+507 PA' },
                { value: '+380', text: '+380 UA' },
                { value: '+598', text: '+598 UY' },
                { value: '+58', text: '+58 VE' },
                { value: '+84', text: '+84 VN' },
                { value: '+54', text: '+54 AR' },
                { value: '+52', text: '+52 MX' },
                { value: '+48', text: '+48 PL' },
                { value: '+386', text: '+386 SI' },
                { value: '+385', text: '+385 HR' },
                { value: '+47', text: '+47 NO' },
                { value: '+40', text: '+40 RO' },
                { value: '+46', text: '+46 SE' },
                { value: '+66', text: '+66 TH' },
                { value: '+56', text: '+56 CL' },
                { value: '+353', text: '+353 IE' },
                { value: '+421', text: '+421 SK' },
                { value: '+358', text: '+358 FI' },
                { value: '+61', text: '+61 AU' },
                { value: '+359', text: '+359 BG' },
                { value: '+36', text: '+36 HU' },
                { value: '+43', text: '+43 AT' },
                { value: '+57', text: '+57 CO' },
                { value: '+1', text: '+1 JM' },
                { value: '+595', text: '+595 PY' },
                { value: '+509', text: '+509 HT' },
                { value: '+995', text: '+995 GE' },
                { value: '+372', text: '+372 EE' },
                { value: '+593', text: '+593 EC' },
                { value: '+962', text: '+962 JO' },
                { value: '+973', text: '+973 BH' },
                { value: '+261', text: '+261 MG' },
                { value: '+65', text: '+65 SG' },
                { value: '+1', text: '+1 US' },
                { value: '+1', text: '+1 CA' },
                { value: '+371', text: '+371 LV' },
                { value: '+961', text: '+961 LB' },
                { value: '+590', text: '+590 FR' },
                { value: '+505', text: '+505 NI' },
                { value: '+351', text: '+351 PT' },
                { value: '+55', text: '+55 BR' },
                { value: '+370', text: '+370 LT' },
                { value: '+51', text: '+51 PE' },
                { value: '+974', text: '+974 QA' },
                { value: '+375', text: '+375 BL' },
                { value: '+233', text: '+233 GH' },
                { value: '+354', text: '+354 IS' },
                { value: '+243', text: '+243 CG' },
                { value: '+1', text: '+1 BS' },
                { value: '+503', text: '+503 SV' }
            ],
            url: '/aviso-legal-privacidad/'
        };
    },
    computed: {
        fpais() {
            return this.$t('masteresPosgrados.openDay.llamada.fpais');
        },
        fcampus() {
            return this.$t('masteresPosgrados.openDay.llamada.fcampus');
        },
        fmaster() {
            return this.$t('masteresPosgrados.openDay.llamada.fmaster');
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.enviar = 0;
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [
                        { name: 'Form Type', value: 'Masteres Posgrados Modal Form' },
                        { name: 'Nombre y apellido', value: this.form.nombre },
                        { name: 'Email', value: this.form.email },
                        // { name: 'Apellido 1', value: this.form.apellido1 },
                        // { name: 'Apellido 2', value: this.form.apellido2 },
                        { name: 'Pais', value: this.form.pais },
                        { name: 'Code', value: this.form.code },
                        { name: 'SMS', value: this.form.sms },
                        { name: 'Campus', value: this.form.campus },
                        { name: 'Master', value: this.form.master },
                        { name: 'Reference Link', value: window.location.href },
                        { name: 'Aceptación Privacidad RGDP:', value: this.form.aceptar }
                    ]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                        this.form = {
                            nombre: null,
                            email: null,
                            apellido1: null,
                            apellido2: null,
                            pais: null,
                            code: '+34',
                            sms: null,
                            campus: null,
                            master: null,
                            aceptar: null,
                            web: null
                        };
                        setTimeout(() => {
                            this.sentSuccess = false;
                        }, 10000);
                    } else {
                        this.sentFailed = true;
                        let send = this.$t('sendERROR');
                        this.$bvToast.toast(send, {
                            title: this.$t('message.rs'),
                            variant: 'default',
                            autoHideDelay: 3000,
                            solid: true
                        });
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        },
        aceptPolitica(checked) {
            this.enviar = checked == 1;
        }
    }
};
</script>
